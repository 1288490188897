<template>
  <div class="toast-message" :class="{ 'error': error }" v-if="toastMessage">
    <span>{{ toastMessage }}</span>
    <div class="progress-bar">
      <div class="progress-fill"></div>
    </div>
  </div>
  <section style="overflow: auto;height: 100vh;background: white;">

    <div
      style="text-align: center;background: #7FB82D;color: white;padding-top: 50px;padding-bottom: 5px;font-weight: 600;">
      <span v-if="mode != 're'" @click="openCatalog()"><u>VIEW OUR CATALOG</u></span>
    </div>

    <div class="main-form">
      <form name="myForm" onsubmit="event.preventDefault();">

        <div class="section" style="border: none;" v-show="!hideModeBar">
          <div class="radio-sec">
            <div @click="mode = 'quote'; logoBase64 = ''">
              <input type="radio" style="margin-right: 5px;margin-top: 3px;" id="quote" name="category" value="quote"
                :checked="mode == 'quote'">
              <label for="quote">QUOTE</label>
            </div>
            <div @click="mode = 'new'; logoBase64 = ''">
              <input type="radio" style="margin-right: 5px;margin-top: 3px;" id="new-order" name="category"
                value="new-order" :checked="mode == 'new'">
              <label for="new-order">NEW ORDER</label>
            </div>
            <div @click="mode = 're'; logoBase64 = ''">
              <input type="radio" style="margin-right: 5px;margin-top: 3px;" id="re-order" name="category"
                value="re-order" :checked="mode == 're'">
              <label for="re-order">RE-ORDER</label>
            </div>
          </div>
        </div>
        <div class="section">
          <h3>CUSTOMER DETAILS</h3>

          <div class="input-wrap" v-if="mode != 're' && submode != 'artwork'">
            <div class="user-box">
              <label>FIRST NAME</label>
              <input type="text" name="customerFirstName" required="true" placeholder="Your First Name" />
            </div>
            <div class="user-box">
              <label>LAST NAME</label>
              <input type="text" name="customerLastName" required="true" placeholder="Your Last Name" />
            </div>
          </div>

          <div class="user-box">
            <label>BRAND NAME</label>
            <input type="text" name="brandName" required="true" placeholder="Your Brand Name" />
          </div>

          <div :class="{ 'input-wrap': mode != 're' }">
            <div class="user-box">
              <label>PHONE</label>
              <input type="tel" name="phone" required="true" placeholder="Your Phone Number" pattern="[0-9]{10}" />
            </div>
            <div class="user-box" v-if="mode != 're'">
              <label>EMAIL</label>
              <input type="email" name="email" placeholder="Your Email Id" required="true" />
            </div>
          </div>
        </div>

        <div class="section" v-if="submode != 'artwork'">
          <h3>BOTTLE(S) DETAILS</h3>

          <div v-if="mode == 're'" class="user-box">
            <label>EXPECTED DELIVERY DATE</label>
            <select name="expectedDelivery">
              <option value="Within a week">Within a week</option>
              <option value="Within 2-3 days">Within 2-3 days</option>
              <option value="Within 24 hours">Within 24 hours</option>
            </select>
          </div>

          <div v-for="(items, idx) in numOfOrders" :key="idx" class="order-box">
            <div
              style="padding-right: 15px;margin-bottom: -13px;color: red;font-size: 17px;display: flex;justify-content: right;">
              <div @click="removeLatestOrder(idx)" style="width:30px; height: 30px;text-align: right;">
                <i v-if="numOfOrders.length > 1" class="fa fa-trash" aria-hidden="true"></i>
              </div>
            </div>
            <div class="input-wrap">
              <div class="user-box">
                <label>BOTTLE SHAPE</label>
                <select v-if="shapes.length" @change="handleSelectShapes($event, idx)" v-model="this.selectedShape[idx]">
                  <option v-for="(item, i) in shapes" :key="i" :value="item">{{ item }}</option>
                </select>
              </div>
              <div class="user-box">
                <label>BOTTLE SIZE</label>
                <select v-if="sizes.length" @change="handleSelectSizes($event, idx)" v-model="this.selectedSize[idx]">
                  <option v-for="(item, i) in sizes[idx]" :key="i" :value="item">{{ item }} ML</option>
                </select>
              </div>
            </div>
            <div class="user-box" v-if="mode != 'quote'">
              <label>QUANTITY (IN CASES)
                {{ selectedProduct[idx] ? `(${selectedProduct[idx].quantityInCase} BOTTLES IN A CASE)` : '' }}
              </label>
              <input v-if="mode == 're'" type="text" name="quantity" required="true"
                placeholder="Quantity (For example: 5, 10, 50, 100)" />
              <select v-else :name="'quantity' + idx" :id="'quantity' + idx" v-model="quantity[idx]">
                <option value="50">50</option>
                <option value="100">100</option>
                <option v-if="mode == 'new'" value="other">Other</option>
              </select>
            </div>

            <div v-if="mode == 'new'" class="user-box">
              <label>MRP IN INR (TO BE PRINTED ON BOTTLE)</label>
              <input type="text" name="mrpOnBottle" required="true" placeholder="MRP IN INR (To be printed on the Bottle)"
                v-model="mrpOnBottle[idx]" />
            </div>

            <div v-if="mode == 'new'" class="user-box">
              <label>BOTTLE CAP COLOUR</label>
              <select :name="'capColor' + idx" :id="'capColor' + idx" v-model="capColor[idx]">
                <option value="Black">Black</option>
                <option value="White">White</option>
              </select>
            </div>

          </div>
          <!-- <div class="order-palette">
            <label>Add Bottle</label>
            <span style="display:flex;">
              <div @click="removeLatestOrder">
                <i class="fa fa-minus-square" aria-hidden="true"></i>
              </div>
              <input type="text" disabled :value="numOfOrders.length" />
              <div @click="addNewOrder">
                <i class="fa fa-plus-square" aria-hidden="true"></i>
              </div>
            </span>
          </div> -->
          <div class="order-palette-1" v-if="numOfOrders.length < 4">
            <span class="o-button" @click="addNewOrder">ADD BOTTLE</span>
          </div>
        </div>

        <div class="section" v-if="mode == 'new'">
          <h3>LABEL DETAILS</h3>
          <div class="file-box">
            <label for="inputTag">UPLOAD LOGO
              <input type="file" id="inputTag" name="logo" style="display: none;" @change="handleInputFile"
                accept=".cdr,.ai,.eps,.ait,.pdf,.svg">
            </label>
            <span id="fileName" class="fake-input"></span>
          </div>
          <div class="user-box">
            <label>BACKGROUND COLOUR</label>
            <input type="text" name="backgroundColor" required="true" placeholder="Colors or color codes" />
          </div>
          <div class="user-box">
            <label>BACKGROUND ARTWORK</label>
            <textarea type="text" name="backgroundArtwork" placeholder="Any pattern/image/graphic in the background" />
          </div>
          <div class="user-box">
            <label>ANY ADDITIONAL INFORMATION</label>
            <textarea type="text" name="additionalInfo"
              placeholder="Add if you have any requests like any additional text or image on label" />
          </div>

        </div>

        <div class="section" v-if="mode != 're' && submode != 'artwork'">
          <h3>DELIVERY DETAILS</h3>

          <div class="user-box">
            <label>ADDRESS</label>
            <input type="text" name="deliveryAddress" required="true" placeholder="Your Delivery Address" />
          </div>

          <div class="input-wrap">
            <div class="user-box">
              <label>CITY</label>
              <input type="text" name="deliveryCity" required="true" placeholder="Your Delivery City Name" />
            </div>
            <div class="user-box">
              <label>ZIP CODE</label>
              <input type="text" name="deliveryZip" required="true" placeholder="Your Delivery Zip Code"
                pattern="[0-9]{6}" />
            </div>
          </div>
        </div>

        <div style="display:flex;margin: 10px;" v-if="mode != 're'">
          <input type="checkbox" name="acceptTnC" required="true" style="margin-right: 5px;margin-top: 3px;"
            @click="showWarningReadTnC" />
          <label style="margin-right: 5px;">I accept all </label>
          <label style="margin-right: 5px; color: #1e7aa5;" @click="showModel = 'tnc'"><u> Terms and
              conditions</u></label>
        </div>

        <div class="submit-box">
          <button @click="submitForm()"> SUBMIT </button>
        </div>
      </form>
    </div>
    <div class="modal" v-if="showModel">
      <div class="modal-body">
        <div class="close-btn" @click="showModel = ''"> <i class="fa fa-times" aria-hidden="true"></i></div>
        <div class="order-success" v-if="showModel == 'orderSuccess'">
          <div class="success-check">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
          </div>
          <div class="tnc">
            <h3>ORDER PLACED SUCCESSFULLY</h3>
            <div style="margin-bottom: 4px;">
              Please save the orderId <strong id="cp_btn" class="copy-span" @click="copyText">{{ orderId }}</strong> for
              any future
              references.
            </div>
            <div>
              <span>
                <strong>For any other queries/assistance kindly contact us on +91 {{ mode == 'new' ? '7987227175': '8770616706'}}.</strong>
              </span>
            </div>
          </div>
        </div>
        <div class="order-success" v-else-if="showModel == 'quote'">
          <div class="tnc">
            <h3>QUOTE</h3>
            <span>
              Thank you for showing interest in our custom bottled water solutions.
            </span>
            <div style="margin-bottom: 4px;overflow: auto;">
              <table v-if="selectedProduct.length">
                <tr>
                  <th v-for="(key, idx) in Object.keys(productQuoteMapper)" :key="idx">{{ productQuoteMapper[key] }}</th>
                </tr>
                <tr v-for="(item, idx) in selectedProduct" :key="idx">
                  <td v-for="(key, idx) in Object.keys(productQuoteMapper)" :key="idx">{{ item[key] }}
                  </td>
                </tr>
              </table>

              <div>
                <span>
                  <strong>Above table contains the best and fixed prices for your branding.</strong>
                </span>
              </div>
              <!-- <div>
                <span>
                  <strong>Note: Gst 18% Applicable.</strong>
                </span>
              </div> -->

            </div>
            <!-- <div>
              <span>
                <strong>For any other queries/assistance kindly contact us on +91 9111111049.</strong>
              </span>
            </div> -->
          </div>
        </div>
        <div class="order-success" v-else-if="showModel == 'welcome'">
          <div class="tnc">
            <div>
              <img class="img-fluid logo"
                src="https://watergram.in/wp-content/uploads/2023/06/logo_0001_Group-1-copy-2.png" alt="nutritius"
                style="height: 50px;">
            </div>
            <h3> Watergram Custom Label</h3>
            <div>
              <span>
                <strong>We provide the best custom label bottle water.</strong>
              </span>
            </div>
            <div>
              <span>
                Custom label bottled water creates a walking billboard for your business and a refreshing way to deliver
                your message. Brand your own custom bottled water today.
              </span>
            </div>
            <div style="margin-bottom: 4px;">
              <span>
                <strong>If you haven't already, click on the below link and take a look at our catalog now.</strong>
              </span>
            </div>
            <div>
              <div class="catalog-box">
                <button @click="openCatalog()">View Our Catalog Now</button>
              </div>
            </div>
            <div>
              <div class="catalog-box">
                <button @click="showModel = ''">Get Quote Now</button>
              </div>
            </div>
            <div>
              <span>
                <strong>For any other queries/assistance kindly contact us on +91 9111111049.</strong>
              </span>
            </div>
          </div>
        </div>
        <div class="order-success" v-else-if="showModel == 'catalog'">
          <div class="tnc">
            <h3> Our Catalog</h3>
            <div style="width: 100%;height: 74vh;overflow: hidden;">
              <iframe height="100%" width="98%" type="application/pdf"
                src="https://drive.google.com/file/d/1ykFP0sau8pY9tHKD8klvvRdUV3gTEgJ_/preview" />
            </div>
          </div>
        </div>
        <div v-if="showModel == 'tnc' || showModel == 'quote'">
          <div class="tnc">
            <h3>TERMS AND CONDITIONS</h3>
            <div style="margin-bottom: 4px;">
              Please read the following <strong>Terms and Conditions</strong> carefully before proceeding to the
              form.
            </div>
            <div class="tnc-points">
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  Minimum Order Quantity Is <strong>50 Boxes Per Delivery</strong> , Smaller Deliveries <strong>Rs
                    500/delivery Across Indore</strong>.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  Minimum <strong>5000 pcs</strong> Stickers will be Printed.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  Your Brand Label Designing & Transportation In Indore City Is Complimentary.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  Deliveries In <strong>Bhopal Rs 20/box, Ujjain — Rs 10/box Applied.</strong>
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  For Investing In Your Labels — We Take A <strong>Refundable Deposit Of 5000/-</strong> Per Bottle
                  Size.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  Deliveries On Ground Floor Only And Delivery Within 20 Feet From Our Vehicle Parked Area Very
                  Strictly.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  We Need <strong>14-16 Days Lead Time For First Delivery</strong> & 4-5 Days In Future.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  Once Refundable Deposit Is Made We Need 7 Days For Artwork To Be Sent For Print.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  Sizes Of All Labels Are Fixed, Hence It Cannot Be Changed.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  We Work On 100% Advance Payment Or Cash/Cheque/RTGS On Delivery.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  <strong>Gst 18% Applicable.</strong>
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  Please upload your original brand logo in <strong>Coral draw format</strong> or <strong>Adobe
                    Illustrator format</strong> in the
                  form.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  Please share in detail any particular preference towards colour and design.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  After succesful submission of the form we require <strong>7 days</strong> to make your label
                  designs.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  We provide <strong>complimentary</strong> designing services for total of <strong>4
                    artworks</strong>. After this the same
                  shall be chargeable at <strong>Rs. 500/- per design</strong>.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  Security Deposit towards your label shall be repaid only after your labels are fully
                  used or we will charge <strong>Rs.1/- per label</strong> of any size & refund the balance.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  In case you would like to change your label in future, we can do so after your old
                  labels are over.
                </span>
              </div>
              <div>
                <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                <span>
                  Kindly check spelling mistakes, address, phone numbers etc. before
                  approving your label once you recieve your prototype labels from design team.
                </span>
              </div>
            </div>
            <div>
              <span>
                <strong>For any other queries/assistance kindly contact us on +91 9111111049.</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop" @click="showModel = ''"></div>
    </div>
    <div v-if="showLoader">
      <div class="loader"><span><img src="../assets/small_spinner.svg" /></span></div>
      <div class="modal-backdrop" @click="showModel = ''"></div>
    </div>
  </section>
</template>
  
<style>
input[type='radio'] {
  /* accent-color: #0033A6; */
  accent-color: #1e7aa5;
}

table {
  margin: 10px 0px;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #2c3e50;
  padding: 8px;
}

.section {
  margin-bottom: 18px;
  /* border: solid 1px; */
}

.main-form {
  width: 100%;
  padding: 18px;
  background: rgba(255, 255, 255);
  box-sizing: border-box;
  /* box-shadow: 0 15px 25px rgba(226, 226, 226, 0.6); */
  border-radius: 5px;
  max-width: 720px;
  margin: 0 auto;
  color: #000000;
}

.main-form h3 {
  margin: 0px 0px 8px 0px;
  padding: 0;
  font-size: 22px;
}

.main-form .user-box {
  position: relative;
  margin: 0 10px;
}

.main-form .input-wrap .user-box {
  width: 50%;
}

.main-form .user-box label {
  font-size: 14px;
  font-weight: 600;
}

.main-form .user-box input {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  outline: none;
  background: transparent;
  border: solid 1px #000000;
  border-radius: 2px;
}

.main-form .user-box select {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  outline: none;
  background: transparent;
  border: solid 1px #000000;
  border-radius: 2px;
}

.main-form .user-box textarea {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  /* margin-bottom: 10px; */
  outline: none;
  background: transparent;
  border: solid 1px #000000;
  border-radius: 2px;
  height: 72px;
}

.order-palette {
  display: flex;
  justify-content: space-between;
  padding-right: 4px;
}

.order-palette div {
  width: 30px;
  text-align: center;
  margin-top: 1px;
  font-size: 20px;
}

.order-palette input {
  width: 60px;
  height: 25px;
  padding: 5px;
  font-size: 14px;
  margin: 4px 0px;
  text-align: center;
  outline: none;
  background: transparent;
  border: solid 1px #000000;
  border-radius: 2px;
}

.order-palette-1 {
  text-align: right;
}

.order-palette-1 .o-button {
  /* height: 30px;
  width: 160px; */
  padding: 8px 32px;
  font-size: 14px;
  border-radius: 2px;
  letter-spacing: 2px;
  font-weight: 900;
  background: #7FB82D;
  color: #f4f4f4;
  text-align: center;
}

.order-box {
  border: 1px solid;
  margin-bottom: 8px;
  padding: 6px 0px;
}

/* .main-form .user-box input:focus~label,
.main-form .user-box input:valid~label {
  top: -15px;
  left: 0;
  font-size: 10px;
}

.main-form .user-box textarea:focus~label,
.main-form .user-box textarea:valid~label {
  top: -15px;
  left: 0;
  font-size: 10px;
} */

.main-form form button {
  padding: 10px 15px;
  border-radius: 2px;
  letter-spacing: 2px;
  font-size: 17px;
  font-weight: 900;
  background: #7FB82D;
  color: #f4f4f4;
  border: none;
  margin: 0 5px;
  width: 100%;
}

.main-form form .submit-box {
  display: flex;
  justify-content: space-around;
  margin: 20px 0 65px;
}

.radio-sec {
  display: flex;
  font-size: 14px;
  margin-bottom: 30px;
  font-weight: 600;
}

.radio-sec div {
  display: flex;
  width: 33%;
  justify-content: center;
}

.input-wrap {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 360px) {
  .radio-sec {
    display: block;
    font-size: 14px;
    margin-bottom: 30px;
  }

  .radio-sec div {
    width: 100%;
  }

  .input-wrap {
    display: block;
  }

  .main-form .input-wrap .user-box {
    width: auto;
  }
}

.file-box {
  font-size: 18px;
  margin: 0px 10px 25px 10px;
  padding: 5px 0px 8px 7px;
  border: solid 1px #000000;
  border-radius: 2px;
}

.file-box label {
  padding: 6px 20px;
  background: #7FB82D;
  border-radius: 2px;
  color: #f4f4f4;
  font-size: 14px;
}

.file-box .fake-input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  padding: 0 5px;
  color: black;
}

.main-form a:hover {
  background: #1b2c51;
  border-radius: 2px;
}

.bg-div {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-img {
  height: 100vh;
}

.modal-backdrop {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background: #51545bbf;
}

.modal-body {
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 90%;
  max-height: 85vh;
  padding: 30px 14px;
  max-width: 720px;
  background: rgb(249 249 249);
  overflow: auto;
}

.loader {
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.loader span img {
  height: 75px;
}

.close-btn {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1;
}


.tnc h3 {
  margin: 0px 0px 8px 0px;
  padding: 0;
  font-size: 22px;
}

.tnc-points div {
  /* display: flex; */
}

.tnc-points div span:first-child {
  margin-right: 3px;
  margin-top: 3px;
  font-size: 10px;
}

.success-check {
  font-size: 78px;
  text-align: center;
  color: green;
}


.order-success {
  text-align: center;
}


.toast-message {
  position: fixed;
  z-index: 99999999;
  top: 30px;
  right: 20px;
  background: #7FB82D;
  color: #f4f4f4;
  font-size: 20px;
  padding: 4px 8px 0px 8px;
  text-align: center;
  border-radius: 2px;
  max-width: 80%;
}

.error {
  background: #a60016;
}


.progress-bar {
  padding-top: 2px;
  margin-bottom: 4px;
  position: relative;
  width: 100%;
  background: var(--grey);
}

.progress-fill {
  position: absolute;
  height: 2px;
  width: 0%;
  animation: progress-forward 3s infinite;
  animation-timing-function: linear;
  background: white;
  border-radius: 15px;
}

@keyframes progress-forward {
  0% {
    width: 0%;
  }

  25% {
    width: 25%;
  }

  50% {
    width: 50%;
  }

  75% {
    width: 75%;
  }

  100% {
    width: 100%;
  }
}

.copy-span {
  padding: 1px 5px;
  border: 1px solid #c3c9d8;
  background: #c3c9d8;
  border-radius: 2px;
}


.catalog-box {
  display: flex;
  justify-content: space-around;
  margin: 8px 0 8px;
}

.catalog-box button {
  padding: 10px 15px;
  border-radius: 2px;
  letter-spacing: 2px;
  font-size: 17px;
  font-weight: 900;
  background: #7FB82D;
  color: #f4f4f4;
  border: none;
  margin: 0 5px;
  width: 100%;
}
</style>

<script>
export default {
  name: 'Form-new',
  data() {
    return {
      start: false,
      guide: false,
      mode: 'quote',
      hideModeBar: false,
      sizes: [],
      shapes: [],
      products: [],
      submode: '',
      selectedProduct: [],
      selectedShape: [],
      selectedSize: [],
      quantity: [],
      mrpOnBottle: [],
      capColor: [],
      numOfOrders: [1],
      showModel: 'welcome',
      orderId: '',
      toastMessage: '',
      error: false,
      logoBase64: '',
      logoFileName: '',
      toastTimeout: null,
      baseAPIURL: 'https://sharedd-api.solebizz.com',
      baseURL: 'https://watergram.solebizz.com',
      productQuoteMapper: {
        // productCode: "Product Code",
        shape: "Shape",
        size: "Size (ML)",
        quantityInCase: "Quantity in case",
        pricePerUnit: "Price per bottle (INR)",
        // pricePerCase: "Price (/case) (INR)"
      },
      showLoader: false
    }
  },
  props: {
    msg: {
      type: String
    }
  },
  async mounted() {
    this.baseAPIURL = window.location.origin.includes('localhost') ? 'http://localhost:11110' : this.baseAPIURL
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.orderType == 'reOrder') {
      this.mode = 're';
      this.hideModeBar = true;
      this.showModel = '';
    } else if (params.orderType == 'artwork') {
      this.mode = 'new';
      this.submode = 'artwork';
      this.customerToken = params.artWorkToken;
      this.hideModeBar = true;
    } else if (params.orderType == 'newOrder') {
      this.mode = 'new';
      this.hideModeBar = true;
    }
    this.products = (await (await fetch(this.baseAPIURL + '/Product/0/100?deleted=false')).json())['data'];
    this.shapes = [...new Set(this.products.map(p => p.shape))];
    this.sizes[0] = this.products.filter(p => p.shape == this.shapes[0]).map(p => p.size);
    this.selectedShape[0] = this.shapes[0];
    this.selectedSize[0] = this.sizes[0][this.sizes[0].indexOf(this.sizes[0].reduce((a, d) => Math.max(a, d), 0))];
    this.quantity[0] = "50";
    this.mrpOnBottle[0] = '';
    this.capColor[0] = 'Black';
    this.selectProduct(0);
  },
  methods: {
    async handleInputFile(e) {
      this.isFileSelected = true;
      const ele = document.getElementById('fileName');
      if (e.target.files && e.target.files.length) {
        ele.innerHTML = e.target.files[0].name;
        this.logoFileName = e.target.files[0].name;
        this.logoBase64 = await this.toBase64(e.target.files[0]);
      }
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    },

    async submitForm() {
      try {
        const form = document.forms["myForm"];

        if (!form.checkValidity()) return;

        this.showLoader = true;

        const category = form["category"]?.value;

        if (category != 're-order') {
          if (category == "new-order" && !this.logoBase64) {
            this.showError('', 'Please upload a brand logo');
            this.showLoader = false;
            return;
          }
          const customerObject = {
            brandName: document.forms["myForm"]["brandName"]?.value,
            backgroundColor: document.forms["myForm"]["backgroundColor"]?.value,
            backgroundArtwork: document.forms["myForm"]["backgroundArtwork"]?.value,
            additionalInfo: document.forms["myForm"]["additionalInfo"]?.value,
            logoFileName: this.logoFileName,
            logoBase64: this.logoBase64,
            customerName: document.forms["myForm"]["customerFirstName"]?.value + " " + document.forms["myForm"]["customerLastName"]?.value,
            email: document.forms["myForm"]["email"]?.value,
            phone: document.forms["myForm"]["phone"]?.value,
            companyName: document.forms["myForm"]["companyName"]?.value,
            deliveryAddress: document.forms["myForm"]["deliveryAddress"]?.value,
            deliveryCity: document.forms["myForm"]["deliveryCity"]?.value,
            deliveryZip: document.forms["myForm"]["deliveryZip"]?.value,
            status: category == 'quote' ? 'lead' : 'active',
            artworkStatus: category == 'new-order' ? 'Submitted' : 'Pending',
            products: this.selectedProduct.map((p, i) => {
              const {
                pricePerCase,
                pricePerUnit,
                productCode,
                quantityInCase,
                shape,
                size,
              } = p;
              return {
                pricePerCase,
                pricePerUnit,
                productCode,
                quantityInCase,
                shape,
                size,
                mrpOnBottle: this.mrpOnBottle[i],
                capColor: this.capColor[i]
              };
            })
          };
          const response = (await
            (await fetch(
              this.baseAPIURL + '/Customer/',
              {
                method: 'POST',
                headers: {
                  Accept: 'application.json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerObject)
              }
            )).json()
          )['data'];

          if (category == 'quote') {
            this.showModel = 'quote';
          } else if (!response) {
            this.showError(response);
            this.showLoader = false;
            return;
          }
        }

        if (category != 'quote') {
          const orderObject = {
            products: this.selectedProduct.map((p, idx) => {
              const {
                pricePerCase,
                pricePerUnit,
                productCode,
                quantityInCase,
                shape,
                size,
              } = p;
              return {
                pricePerCase,
                pricePerUnit,
                productCode,
                quantityInCase,
                shape,
                size,
                quantity: parseInt(this.quantity[idx] || 0)
              };
            }),
            phone: document.forms["myForm"]["phone"]?.value,
            brandName: document.forms["myForm"]["brandName"]?.value,
            expectedDeliveryDate: document.forms["myForm"]["expectedDelivery"]?.value,
            orderType: category != 're-order' ? 'New-Order' : 'Re-Order'
          };
          const response = (await
            (await fetch(
              this.baseAPIURL + '/Order/',
              {
                method: 'POST',
                headers: {
                  Accept: 'application.json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(orderObject)
              }
            )).json()
          )['data'];

          if (!response.orderId) {
            this.showError(response);
            this.showLoader = false;
            return;
          }

          this.orderId = response.orderId;
          this.showModel = 'orderSuccess';
        }
        this.showLoader = false;
      } catch (err) {
        console.log(err);
      }
    },

    addNewOrder() {
      if (this.numOfOrders.length < 4) {
        this.selectedShape[this.numOfOrders.length] = this.shapes[0];
        this.sizes[this.numOfOrders.length] = this.products.filter(p => p.shape == this.shapes[0]).map(p => p.size);
        this.selectedSize[this.numOfOrders.length] = this.sizes[this.numOfOrders.length][this.sizes[this.numOfOrders.length].indexOf(
          this.sizes[this.numOfOrders.length].reduce((a, d) => Math.max(a, d), 0)
        )];
        this.selectProduct(this.numOfOrders.length);
        this.quantity[this.numOfOrders.length] = "50";
        this.mrpOnBottle[this.numOfOrders.length] = '';
        this.capColor[this.numOfOrders.length] = 'Black';
        this.numOfOrders.push(this.numOfOrders.length + 1);
      }
    },

    removeLatestOrder(idx) {
      if (this.numOfOrders.length > 1) {
        if (typeof idx == 'number') {
          this.selectedShape.splice(idx, 1);
          this.selectedSize.splice(idx, 1);
          this.selectedProduct.splice(idx, 1);
          this.quantity.splice(idx, 1);
          this.mrpOnBottle.splice(idx, 1);
          this.capColor.splice(idx, 1);
          this.numOfOrders.splice(idx, 1);
        } else {
          this.selectedShape.pop();
          this.selectedSize.pop();
          this.selectedProduct.pop();
          this.quantity.pop();
          this.mrpOnBottle.pop();
          this.capColor.pop();
          this.numOfOrders.pop();
        }
      }
    },

    handleSelectShapes(e, idx) {
      this.selectedShape[idx] = e.target.value;
      this.updateSizeList(idx);
      this.selectProduct(idx);
    },

    handleSelectSizes(e, idx) {
      this.selectedSize[idx] = e.target.value;
      this.selectProduct(idx);
    },

    updateSizeList(idx) {
      this.sizes[idx] = this.products.filter(p => p.shape == this.selectedShape[idx]).map(p => p.size);
      this.selectedSize[idx] = this.sizes[idx][this.sizes[idx].indexOf(
        this.sizes[idx].reduce((a, d) => Math.max(a, d), 0)
      )];
    },

    selectProduct(idx) {
      this.selectedProduct[idx] = this.products.filter(p => p.shape == this.selectedShape[idx] && p.size == this.selectedSize[idx])[0];
    },

    copyText() {
      const copyText = document.getElementById("cp_btn");
      const textArea = document.createElement("textarea");
      textArea.value = copyText.textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("Copy");
      textArea.remove();
      this.error = false;
      this.toastMessage = 'Copied';
      clearTimeout(this.toastTimeout);
      this.toastTimeout = setTimeout(() => this.toastMessage = '', 3000);
    },

    showWarningReadTnC(e) {
      if (e.target.value) {
        this.error = false;
        clearTimeout(this.toastTimeout);
        this.toastMessage = 'Please read our Terms and Conditions carefully.';
        this.toastTimeout = setTimeout(() => this.toastMessage = '', 3000);
      }
    },

    showError(response, message = '') {
      this.error = true;
      this.toastMessage = message || 'Error';
      clearTimeout(this.toastTimeout);
      this.toastTimeout = setTimeout(() => { this.toastMessage = ''; this.error = false }, 3000);
    },

    openCatalog() {
      // const URL = window.location.origin.includes('localhost') ? 'http://localhost:8080' : this.baseURL
      // window.open(URL + "/catalog_cmp.pdf", '_blank');
      this.showModel = 'catalog';
    },

    downloadCatalog() {
      const URL = window.location.origin.includes('localhost') ? 'http://localhost:8080' : this.baseURL
      const link = document.createElement('a');
      link.href = URL + "/catalog_cmp.pdf";
      link.download = 'catalog.pdf';
      link.dispatchEvent(new MouseEvent('click'));
    }
  },
}
</script>
  